export default {
  bold: true,
  italic: true,
  header: true,
  underline: false,
  strikethrough: false,
  mark: false,
  superscript: false,
  subscript: false,
  quote: true,
  ol: true,
  ul: true,
  link: true,
  imagelink: true,
  code: true,
  table: false,
  fullscreen: false,
  readmodel: false,
  htmlcode: false,
  help: false,
  /* 1.3.5 */
  undo: false,
  redo: false,
  trash: false,
  save: false,
  /* 1.4.2 */
  navigation: false,
  /* 2.1.8 */
  alignleft: false,
  aligncenter: false,
  alignright: false,
  /* 2.2.1 */
  subfield: false,
  preview: false
}
