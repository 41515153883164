<template>
  <v-form
    ref="form"
    v-model="formIsValid"
  >
    <v-sheet outlined>
      <v-card-text class="pa-2">
        <div class="d-flex align-center justify-start mb-2">
          <slack-avatar
            class="mr-2"
            :user="comment.user"
            :size="32"
          />
          <div class="d-flex align-center justify-space-between flex-grow-1">
            <div
              v-line-clamp="1"
            >
              {{ comment.user.name }} added
              <code>{{ comment.category ? comment.category.display_name : 'Comment' }}</code>
              in
              <router-link
                :to="`/event/${$route.params.eventSlug}/${comment.project.random_key}`"
                v-text="comment.project.name"
              />
              <span class="caption text--disabled">
                {{ comment.created | utcAsLocal | moment("from", "now") }}
              </span>
            </div>
            <div>
              <v-btn
                v-if="(!hideEditButtonFromNormalUsers && currentUserIsOwner) || currentUserIsEventOwner || currentUserIsEventModerator"
                class="ma-0"
                color="primary"
                :disabled="updateLoading"
                depressed
                small
                @click="toggleEditMode()"
              >
                {{ currentUserIsEventOwner || currentUserIsEventModerator ? 'Edit' : 'Resolve' }} mode {{ editMode ? 'OFF': 'ON' }}
              </v-btn>
            </div>
          </div>
        </div>
        <div
          v-if="comment.tags.length"
          class="mb-2"
        >
          <v-chip
            v-for="tag in comment.tags"
            :key="tag.id"
            class="mr-2"
            disabled
            x-small
            label
          >
            {{ tag.tag.display_name }}
          </v-chip>
        </div>
        <v-sheet
          v-if="editMode"
          class="pa-2"
          outlined
        >
          <div v-if="showResolution">
            <v-select
              v-model="selectedResolution"
              class="mb-2"
              background-color="white"
              :items="currentEvent.commentResolutions"
              item-text="name"
              item-value="id"
              label="Comment Resolution"
              return-object
              clearable
              hide-details
              outlined
            >
              <template v-slot:item="{ item }">
                <div>
                  <div>
                    {{ item.title }}
                  </div>
                  <div class="text-caption text--disabled">
                    {{ item.description }}
                  </div>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div>
                  <div>
                    {{ item.title }}
                  </div>
                  <div class="text-caption text--disabled">
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </v-select>
            <v-text-field
              v-model="resolutionValue"
              background-color="white"
              label="Resolution Note (optional)"
              clearable
              outlined
            />
          </div>
          <div v-if="currentUserIsEventOwner || currentUserIsEventModerator">
            <v-select
              v-model="selectedCategory"
              class="mb-2"
              background-color="white"
              :items="currentEvent.commentCategories"
              item-text="name"
              item-value="id"
              label="Comment Category"
              return-object
              clearable
              hide-details
              outlined
            >
              <template v-slot:item="{ item }">
                <div>
                  <div>
                    {{ item.display_name }}
                  </div>
                  <div class="text-caption text--disabled">
                    {{ item.description }}
                  </div>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div>
                  <div>
                    {{ item.display_name }}
                  </div>
                  <div class="text-caption text--disabled">
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </v-select>
            <v-autocomplete
              v-model="selectedTags"
              class="mb-2"
              background-color="white"
              :items="currentEvent.tags"
              item-text="display_name"
              item-value="id"
              label="Related Tags"
              :menu-props="{ offsetY: true }"
              :disabled="!currentEvent.tags.length"
              return-object
              clearable
              multiple
              hide-details
              outlined
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  label
                  close
                  @click:close="selectedTags = selectedTags.filter(tag => tag.id !== item.id)"
                >
                  {{ item.display_name }}
                </v-chip>
              </template>
            </v-autocomplete>
            <mavon-editor
              v-if="!comment.category"
              ref="editor-0"
              v-model="commentValue"
              class="mb-6"
              placeholder="Your comment"
              :toolbars="require('@/mavonToolbarsMinimal').default"
              :box-shadow="false"
              language="en"
              @imgAdd="(pos, $file) => $imgAdd(pos, $file, 'editor-0')"
            />
            <mavon-editor
              v-else-if="comment.category.type === 'text'"
              ref="editor-1"
              v-model="commentValue"
              class="mb-6"
              :placeholder="comment.category.description"
              :toolbars="require('@/mavonToolbarsMinimal').default"
              :box-shadow="false"
              language="en"
              @imgAdd="(pos, $file) => $imgAdd(pos, $file, 'editor-1')"
            />
            <v-text-field
              v-else-if="comment.category.type === 'number'"
              v-model.number="commentValue"
              :label="comment.category.description"
              :rules="[
                (v) => !!v || 'This field is required',
                (v) => _.isNumber(v) && parseInt(v) > 0 || 'Must be a positive numerical value',
              ]"
              type="number"
              clearable
              outlined
            />
          </div>
          <v-btn
            :loading="updateLoading"
            :disabled="updateLoading || !formIsValid || !commentValue"
            color="primary"
            depressed
            @click="updateMutation(
              comment.id,
              {
                value: `${commentValue}`,
                category_id: selectedCategory ? selectedCategory.id : null,
                resolution_id: selectedResolution ? selectedResolution.id : null,
                resolution_note: resolutionValue ? `${resolutionValue}` : null,
                tags: selectedTags.map(tag => tag.id)
              }
            )"
          >
            Save
          </v-btn>
        </v-sheet>
        <v-sheet
          v-else
          class="pa-2"
          outlined
        >
          <vue-markdown
            class="markdown-body"
            :source="comment.value"
            :anchor-attributes="{ target: '_blank' }"
          />
        </v-sheet>
        <v-sheet
          v-if="comment.resolution"
          class="pa-2 mt-2"
          color="primary"
          dark
        >
          <h6 class="text-h6">Resolved as "{{ comment.resolution.title }}"</h6>
          <v-sheet
            v-if="comment.resolution_note"
            class="pa-2 mt-2"
            color="white"
          >
            <vue-markdown
              class="markdown-body t"
              :source="comment.resolution_note"
              :anchor-attributes="{ target: '_blank' }"
            />
          </v-sheet>
        </v-sheet>
      </v-card-text>
    </v-sheet>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'

import SlackAvatar from './SlackAvatar'

export default {
  name: 'ProjectComment',
  components: { SlackAvatar },
  props: {
    comment: {
      type: Object,
      default: () => ({})
    },
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    },
    showResolution: {
      type: Boolean,
      default: false
    },
    hideEditButtonFromNormalUsers: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formIsValid: false,
    editMode: false,
    selectedCategory: null,
    selectedResolution: null,
    commentValue: '',
    resolutionValue: '',
    selectedTags: [],
    deleteLoading: false,
    updateLoading: false,
    isResolved: {
      color: 'green',
      lightness: 'lighten-5',
      lightnessAccent: 'lighten-4'
    },
    notResolved: {
      color: 'red',
      lightness: 'lighten-4',
      lightnessAccent: 'lighten-3'
    }
  }),
  computed: {
    ...mapState('user', ['claims']),
    currentEvent () {
      if (!this.$route.params.eventSlug) return null
      const client = this.$apollo.getClient()
      const { event } = client.readQuery({
        query: require('@/gql/getEvent').default,
        variables: {
          slug: this.$route.params.eventSlug
        }
      })
      return event
    },
    currentUserIsOwner () {
      return this.comment.user.id === this.claims.sub
    },
    currentUserIsEventOwner () {
      return this.currentEvent.owner.id === this.claims.sub
    },
    currentUserIsEventModerator () {
      return !!this._.find(this.currentEvent.moderators, moderator => moderator.user.id === this.claims.sub)
    },
    projectId () {
      return this.comment.project ? this.comment.project.id : this.project.id
    },
    projectName () {
      return this.comment.project ? this.comment.project.name : this.project.name
    }
  },
  methods: {
    toggleEditMode (value) {
      if (value) this.editMode = value
      else this.editMode = !this.editMode
      if (this.comment.category && this.comment.category.type === 'number') this.commentValue = parseInt(this.comment.value)
      else this.commentValue = this.comment.value
      this.resolutionValue = this.comment.resolution_note
      this.selectedCategory = this.comment.category
      this.selectedResolution = this.comment.resolution
      this.selectedTags = this.comment.tags.map(tag => tag.tag)
    },
    async $imgAdd (pos, $file, refName) {
      try {
        const formData = new FormData()
        formData.append('event', this.event.id)
        formData.append('project', this.project.id)
        formData.append('image', $file)
        const response = await this.$api.post(
          '/upload-image',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        if (!response.data.ok) {
          throw new Error(response.data.output)
        } else {
          this.$refs[refName].$img2Url(pos, response.data.output.viewImage)
        }
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'Mavon Error',
          text: error.message,
          duration: 10000
        })
      }
    },
    async updateMutation (id, value) {
      this.updateLoading = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateProjectComment').default,
          variables: { id, value },
          update: (store, { data: { updateProjectComment } }) => {
            // Read from cache
            const data = store.readQuery({
              query: require('@/gql/getCommentsByEvent').default,
              variables: {
                slug: this.$route.params.eventSlug,
                user_email: this.$route.params.userEmail
              }
            })
            // Write it back
            store.writeQuery({
              query: require('@/gql/getCommentsByEvent').default,
              variables: {
                slug: this.$route.params.eventSlug,
                user_email: this.$route.params.userEmail
              },
              data: {
                comments: this._.unionBy(data.comments, [updateProjectComment], 'id')
              }
            })
          }
        })
      } catch (error) {
        console.error(error)
      }
      this.updateLoading = false
      this.toggleEditMode(false)
    }
  }
}
</script>
