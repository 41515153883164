import gql from 'graphql-tag'
import TagAllFields from './fragments/TagAllFields'

export default gql`
  query getEventTag ($slug: String!, $name: String!) {
    eventTag (slug: $slug, name: $name) {
      ...TagAllFields
      rating
    }
  }
  ${TagAllFields}
`
