<template>
  <div>
    <v-tooltip
      :open-delay="openDelay"
      bottom
    >
      <template
        v-slot:activator="{ on }"
      >
        <v-avatar
          :class="{ 'clickable': user.slack_id }"
          :size="size"
          v-on="on"
          @click="openSlack()"
        >
          <img
            v-if="user.slack_picture"
            :src="user.slack_picture"
            :alt="user.name"
          >
          <v-icon
            v-else
          >
            mdi-account
          </v-icon>
        </v-avatar>
      </template>
      <span>
        {{ user.name }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'SlackAvatar',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    openDelay: {
      type: Number,
      default: 500
    },
    size: {
      type: Number,
      default: 32
    }
  },
  data: () => ({
  }),
  methods: {
    openSlack () {
      if (this.user.slack_id) {
        location.href = `slack://user?team=T06AF9667&id=${this.user.slack_id}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .clickable {
    cursor: pointer;
  }
</style>
