import gql from 'graphql-tag'
import UserAllFields from './UserAllFields'
import CommentCategoryAllFields from './CommentCategoryAllFields'
import CommentResolutionAllFields from './CommentResolutionAllFields'
import CommentDecisionAllFields from './CommentDecisionAllFields'
import TagAllFields from './TagAllFields'

export default gql`
  fragment CommentAllFields on ProjectComment {
    id
    user {
      ...UserAllFields
    }
    project {
      id
      random_key
      name
    }
    category {
      ...CommentCategoryAllFields
    }
    decisions {
      ...CommentDecisionAllFields
    }
    resolution {
      ...CommentResolutionAllFields
    }
    resolution_note
    value
    tags {
      id
      tag {
        ...TagAllFields
      }
    }
    created
    updated
  }
  ${UserAllFields}
  ${CommentCategoryAllFields}
  ${CommentResolutionAllFields}
  ${CommentDecisionAllFields}
  ${TagAllFields}
`
