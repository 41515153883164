<template>
  <v-card
    class="ma-0"
    height="100%"
    :color="comment.decisions.length ? 'green lighten-5' : 'red lighten-5'"
    outlined
  >
    <v-card-text
      class="d-flex align-start justify-space-between fill-height"
    >
      <v-row
        v-if="event"
        dense
      >
        <v-col :cols="8">
          <project-comment
            :comment="comment"
            :event="event"
            hide-edit-button-from-normal-users
            show-resolution
          />
        </v-col>
        <v-col :cols="4">
          <div class="mb-2">
            <v-select
              v-model="value"
              class="mb-2"
              label="Decision"
              :items="['Yes, we need to fix this, please report it', 'We are already aware of this issue', 'That is by design', 'No, we are not fixing this']"
              hide-details
              clearable
              outlined
              dense
            />
            <v-textarea
              v-model="additional"
              class="mb-2"
              label="Comment (optional)"
              :rows="2"
              hide-details
              full-width
              outlined
              dense
            />
            <v-btn
              color="primary"
              small
              :loading="loading.create"
              :disabled="loading.create || (!value && !additional)"
              depressed
              @click="createMutation"
            >
              Send
            </v-btn>
          </div>
          <div
            v-for="decision in comment.decisions"
            :key="decision.id"
            class="d-flex align-start mb-2"
          >
            <slack-avatar
              class="mr-2"
              :user="decision.user"
            />
            <v-card class="ma-0 pa-2">
              <code
                v-if="decision.value"
                class="caption"
                v-text="decision.value"
              />
              <v-sheet
                v-if="decision.additional"
                class="pa-2"
                :class="{ 'mt-2': decision.value }"
                color="grey lighten-3"
              >
                {{ decision.additional }}
              </v-sheet>
            </v-card>
            <v-btn
              icon
              @click="deleteMutation(decision.id)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import SlackAvatar from './components/core/SlackAvatar'
import ProjectComment from './components/core/ProjectCommentIsolated'

export default {
  name: 'DashboardReportTagItem',
  components: { SlackAvatar, ProjectComment },
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    comment: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    value: '',
    additional: null,
    loading: {
      create: false,
      delete: false
    },
    allowedKeys: [
      'value',
      'additional'
    ]
  }),
  computed: {
    ...mapState('user', ['claims'])
  },
  methods: {
    async createMutation () {
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createProjectCommentDecision').default,
          variables: { comment_id: this.comment.id, value: this.value, additional: this.additional },
          update: (store, { data: { createProjectCommentDecision } }) => {
            // Read from cache
            const data = store.readQuery({
              query: require('@/gql/getCommentsByEvent').default,
              variables: {
                slug: this.$route.params.eventSlug,
                tag_name: this.$route.params.tagName
              }
            })
            const currentComment = this._.find(data.comments, { id: this.comment.id })
            currentComment.decisions = [createProjectCommentDecision, ...currentComment.decisions]
            // Write it back
            store.writeQuery({
              query: require('@/gql/getCommentsByEvent').default,
              variables: {
                slug: this.$route.params.eventSlug,
                tag_name: this.$route.params.tagName
              },
              data
            })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createProjectCommentDecision: {
              __typename: 'ProjectCommentDecision',
              id: -1,
              user: {
                __typename: 'User',
                email: this.claims.email,
                id: this.claims.sub,
                manager: null,
                name: this.claims.name,
                slack_id: null,
                slack_picture: null
              },
              value: this.value,
              additional: this.additional
            }
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully added Decision'
        })
        this.value = ''
        this.additional = null
      } catch (error) {
        console.error(error)
      }
      this.loading.create = false
    },
    async deleteMutation (id) {
      const execute = await this.$confirm(
          `
            Do you really want to delete this decision?<br /><br />
            <strong class="red--text">This cannot be undone!</strong>
          `,
          {
            color: 'red',
            icon: 'mdi-alert'
          }
      )
      if (!execute) return
      this.loading.delete = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/deleteProjectCommentDecision').default,
          variables: { id },
          update: (store, { data: { deleteProjectCommentDecision } }) => {
            console.log('deleteProjectCommentDecision', deleteProjectCommentDecision)
            const data = store.readQuery({
              query: require('@/gql/getCommentsByEvent').default,
              variables: {
                slug: this.$route.params.eventSlug,
                tag_name: this.$route.params.tagName
              }
            })
            const currentComment = this._.find(data.comments, { id: this.comment.id })
            console.log(currentComment.decisions)
            currentComment.decisions = currentComment.decisions.filter(decision => decision.id !== deleteProjectCommentDecision.id)
            // Write it back
            store.writeQuery({
              query: require('@/gql/getCommentsByEvent').default,
              variables: {
                slug: this.$route.params.eventSlug,
                tag_name: this.$route.params.tagName
              },
              data
            })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteProjectCommentDecision: {
              __typename: 'ProjectCommentDecision',
              id
            }
          }
        })
      } catch (error) {
        console.error(error)
      }
      this.loading.delete = false
    }
  }
}
</script>
