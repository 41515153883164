import gql from 'graphql-tag'
import CommentDecisionAllFields from './fragments/CommentDecisionAllFields'

export default gql`
  mutation createProjectCommentDecision ($comment_id: Int!, $value: String, $additional: String) {
    createProjectCommentDecision (comment_id: $comment_id, value: $value, additional: $additional) {
      ...CommentDecisionAllFields
    }
  }
  ${CommentDecisionAllFields}
`
