import gql from 'graphql-tag'
import UserAllFields from './UserAllFields'

export default gql`
  fragment CommentDecisionAllFields on ProjectCommentDecision {
    id
    user {
      ...UserAllFields
    }
    value
    additional
  }
  ${UserAllFields}
`
