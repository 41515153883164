import gql from 'graphql-tag'
import CommentAllFields from './fragments/CommentAllFields'

export default gql`
  query getCommentsByEvent ($event_id: Int, $slug: String, $tag_id: Int, $tag_name: String, $user_id: String, $user_email: String, $limit: Int) {
    comments (event_id: $event_id, slug: $slug, tag_id: $tag_id, tag_name: $tag_name, user_id: $user_id, user_email: $user_email, limit: $limit) {
      ...CommentAllFields
    }
  }
  ${CommentAllFields}
`
