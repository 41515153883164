import gql from 'graphql-tag'
import CommentAllFields from './fragments/CommentAllFields'

export default gql`
  mutation updateProjectComment ($id: Int!, $value: ProjectCommentInput!) {
    updateProjectComment (id: $id, value: $value) {
      ...CommentAllFields
    }
  }
  ${CommentAllFields}
`
