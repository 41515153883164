<template>
  <v-container fluid>
    <v-overlay
      v-if="$apollo.queries.event.loading || $apollo.queries.comments.loading"
      color="white"
      opacity="1"
      absolute
    >
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      />
    </v-overlay>
    <template v-else>
      <v-row
        class="mb-4"
        dense
      >
        <v-col :cols="12">
          <div class="d-flex align-center">
            <div class="mr-2">
              Participant satisfaction:
            </div>
            <v-rating
              empty-icon="mdi-star-outline"
              half-icon="mdi-star-half-full"
              full-icon="mdi-star"
              background-color="grey"
              color="primary"
              :length="5"
              :size="16"
              :value="eventTag.rating"
              half-increments
              readonly
              dense
            />
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select
            v-model="filterByCategorySelection"
            :items="uniqueCategories"
            label="Filter By Category"
            outlined
          />
        </v-col>
        <v-col
          class="d-flex"
          cols="4"
        >
          <v-switch
            v-model="hideResolved"
            class="mr-4"
            label="Hide resolved"
          />
          <v-switch
            v-model="hideDecided"
            label="Hide decided"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-for="comment in filteredComments"
          :key="comment.id"
          :cols="12"
        >
          <report-tag-item
            :event="event"
            :comment="comment"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ReportTagItem from './ReportTagItem'

export default {
  name: 'DashboardReportTag',
  components: { ReportTagItem },
  data: () => ({
    filterByCategorySelection: 'All',
    hideResolved: true,
    hideDecided: false
  }),
  computed: {
    ...mapState('user', ['claims']),
    uniqueCategories () {
      return ['All', ...this._(this.comments).map('category.display_name').uniq().remove(null), 'Without Category'].sort()
    },
    filteredComments () {
      return this.comments
        .filter(this.filterByCategory)
        .filter(this.filterByResolutionBoolean)
        .filter(this.filterByDecisionBoolean)
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    filterByCategory (comment) {
      if (this.filterByCategorySelection === 'All') return true
      else if (this.filterByCategorySelection === 'Without Category') return !comment.category
      else return comment.category && comment.category.display_name === this.filterByCategorySelection
    },
    filterByResolutionBoolean (comment) {
      if (!this.hideResolved) return true
      return !comment.resolution
    },
    filterByDecisionBoolean (comment) {
      if (!this.hideDecided) return true
      return !comment.decisions.filter(decision => decision.value).length
    }
  },
  apollo: {
    event: {
      query: require('@/gql/getEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug
        }
      }
    },
    comments: {
      query: require('@/gql/getCommentsByEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug,
          tag_name: this.$route.params.tagName
        }
      }
    },
    eventTag: {
      query: require('@/gql/getEventTag').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug,
          name: this.$route.params.tagName
        }
      }
    }
  }
}
</script>
